@import "./setup.scss";

.header {
  height: $header-mobile;
}
.footer {
  height: $footer-mobile;
}

.header-clearence {
  margin-top: $header-mobile !important;
}

.footer-clearence {
  margin-bottom: $footer-mobile !important;
}

.snackbar-container {
  margin-top: $header-mobile !important;
  top: 0 !important;
}
.snackbar {
  .MuiPaper-root {
    background-color: var(--brand-secondary);
  }
}
.snackbar-error {
  background-color: #d32f2f !important;
}

.icon-label {
  font-size: 12px !important;
  margin-top: -12px !important;
}

.loading-icon {
  padding: 10px;
  background: white;
  border-radius: 100%;
}

.fab-label {
  margin-top: 4px !important;
  font-size: 12px !important;
}

.small-label {
  font-size: 12px !important;
}
.root-small-label {
  margin-right: 16px !important;
}

.content-max-width {
  max-width: 425px;
}

.fab-max-width {
  max-width: 575px;
}

.pointer {
  cursor: pointer !important;
}

.shadow-top {
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.fullscreen {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.top-right {
  top: 0;
  right: 0;
}
.bottom {
  bottom: 0;
  left: 0;
  right: 0;
}
.bottom-right {
  bottom: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.top-left {
  top: 0;
  left: 0;
}

.z-index-high {
  z-index: 3;
}

.z-index-normal {
  z-index: 0;
}

.z-index-low {
  z-index: -3;
}

.img-shadow {
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
}

.no-scroll {
  overflow: hidden;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
