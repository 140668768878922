$header-mobile: 120px;
$footer-mobile: 90px;

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  line-height: 1.6;
}

.grecaptcha-badge {
  visibility: hidden; 
}

body{
  background-color: #212a45;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}

button:focus,
li:focus,
input:focus {
  outline: none;
}

.btn:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.MuiFilledInput-root {
  background: white !important;
}

.MuiFilledInput-underline:before{
  border-bottom: none !important;
}

.MuiInput-underline:before{
  border-bottom: 1px solid #dee2e6 !important;
}

.bg-light{
  background: rgb(240, 240, 240) !important;
}

