.counter{
    width:10px;
    height:10px;
    border-radius:20px;
    transform: translate(110%,-15%);
    font-size: 10px;
    line-height: 10px;
}

.logo{
    width: 110px;
}
.logo-large{
    width: 160px;
}